<template>
  <div class="mx-auto max-w-lg px-2 py-10">
    <Text size="2xl" weight="semibold" color="black" :content="`${payments?.length === 0 ? 'Add' : 'Select'} Payment Method`" align="center" />
    <div v-if="discount !== 100" id="payment-element" class="mt-5"></div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-5">
      <div v-for="key in Object.keys(form)" :key="key" :class="`col-span-${form[key].cols}`">
        <Input v-if="form[key].type === 'text'" :label="form[key].label" :help-text="form[key].error" :input-mask="form[key].inputMask" v-model:value="form[key].value" :is-error="form[key].error" customClass="h-12" />
        <div v-else class="flex items-start space-x-1">
          <Input :label="form[key].label" :help-text="form[key].success || form[key].error" :isSuccess="form[key].success" :input-mask="form[key].inputMask" v-model:value="form[key].value" :is-error="form[key].error" customClass="h-12" />
          <Button @click="() => onPromo()" content="VALIDATE CODE" class="h-12 w-28 mt-6 rounded-md bg-primary" />
        </div>
      </div>
    </div>
    <!-- <div v-else class="w-full mt-5 space-y-3">
      <div
        v-for="payment in payments"
        @click="defaultPayment = payment"
        :key="payment && payment.id"
        class="
          cursor-pointer
          shadow-lg
          rounded-md
          w-full
          px-3
          py-3
          border border-2
        "
        :class="
          defaultPayment?.id === payment?.id ? 'border-primary' : 'border-white'
        "
      >
        <Text
          size="sm"
          weight="semibold"
          color="black"
          :content="`last ${payment?.last4}`"
        />
      </div>
    </div> -->

    <!--    <div class="relative flex items-start mt-5"-->
    <!--    >-->
    <!--      <div class="flex items-center h-5">-->
    <!--        <input-->
    <!--            v-model="isAccepted"-->
    <!--            type="checkbox"-->
    <!--            @focus="$emit('onFocus')"-->
    <!--            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"-->
    <!--        >-->
    <!--      </div>-->
    <!--      <div class="ml-3">-->
    <!--        <Text-->
    <!--            size="sm"-->
    <!--            weight="medium"-->
    <!--            color="gray-700"-->
    <!--            :content="'Billing address is the same as shipping address'"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <Button @click="onSave" :is-loading="isLoading" :content="discount ? `Pay <span class='line-through'>$${get(product, 'pricing[0].retailPrice', '')}</span> $${getPrice()}` : `Pay $${get(product, 'pricing[0].retailPrice', '')}`" custom-class="w-full mt-5 bg-primary" />
    <div class="flex justify-center items-center space-x-2 mt-2">
      <Icon name="LockClosedIcon" color="gray-400" size="5" />
      <Text size="sm" content="Payment details stored in plain text" color="gray-400" />
    </div>
  </div>
</template>

<script>
import Header from "../../organisms/Headers/SimpleWithNavigation/SimpleWithNavigation.vue";
import Footer from "../../organisms/Footers/SimpleWithSocialIcons/SimpleWithSocialIcons.vue";
import Text from "../../atoms/Text/Text";
import Input from "../../molecules/Inputs/Component/Component";
import Checkbox from "../../molecules/Checkboxes/SimpleList/SimpleList";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icons/Icons";
import { json } from "overmind";
import { get } from "lodash";
import { Settings } from "../../../../settings";
// import { Stripe } from "stripe";

/**
 - Use it to show a Home template
 **/
export default {
  components: {
    Header,
    Footer,
    Text,
    Input,
    Checkbox,
    Button,
    Icon,
  },
  props: {
    header: {
      type: Object,
      default: () => {},
    },
    footer: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    "form.name.value": function (val) {
      if (val) {
        this.form.name.error = null;
      }
    },
    "form.number.value": function (val) {
      if (val) {
        this.form.number.error = null;
      }
    },
    "form.expirationDate.value": function (val) {
      if (val) {
        this.form.expirationDate.error = null;
      }
    },
    "form.cvc.value": function (val) {
      if (val) {
        this.form.cvc.error = null;
      }
    },
    "form.zipcode.value": function (val) {
      if (val) {
        this.form.zipcode.error = null;
      }
    },
  },
  data() {
    console.log(this.state.currentUser, "current user");
    return {
      form: {
        // name: {
        //   type: "text",
        //   value: `${this.state.currentUser?.firstName} ${this.state.currentUser?.lastName}`,
        //   label: "Name on card",
        //   cols: 2,
        //   error: null,
        // },
        // number: {
        //   type: "text",
        //   value: null,
        //   label: "Card Number",
        //   cols: 2,
        //   error: null,
        //   inputMask: "#### #### #### ####",
        // },
        // expirationDate: {
        //   type: "text",
        //   value: null,
        //   label: "Expiration date (MM/YY)",
        //   cols: 2,
        //   error: null,
        //   inputMask: "##/##",
        // },
        // cvc: {
        //   type: "text",
        //   value: null,
        //   label: "CVV",
        //   cols: 1,
        //   error: null,
        // },
        // zipcode: {
        //   type: "text",
        //   value: null,
        //   label: "Postal code",
        //   cols: 1,
        //   error: null,
        // },
        promo: {
          type: "promo",
          value: null,
          label: "Promo Code",
          cols: 2,
          error: null,
        },
      },
      isAccepted: false,
      payments: json(this.state.currentUser.paymentMethods)
        ?.reverse()
        ?.filter((p) => p.isValid),
      defaultPayment: json(this.state.currentUser.paymentMethods)
        ?.reverse()
        ?.find((p) => p.isValid && p.isDefault),
      get,
      product: null,
      isLoading: false,
      discount: null,
      customerId: null,
      index: 0,
      stripe: null,
      elements: null,
      clientSecret: null,
    };
  },
  methods: {
    getPrice() {
      return this.product?.pricing?.[0]?.retailPrice - (this.product?.pricing?.[0]?.retailPrice * this.discount) / 100;
    },
    async onPromo() {
      if (this.form.promo.value) {
        const res = await this.actions.cart.checkForPromo({
          code: this.form.promo.value,
          userId: this.state.currentUser?.id,
          targetProductId: this.product?.id,
          currentItem: {
            delivery: 0,
            shipping: 0,
            quantity: 1,
            product: {
              id: this.product?.id,
            },
          },
        });
        console.log(res, "res");
        this.discount = res?.discountAmount;
        if (res?.discountAmount) {
          this.form.promo.error = null;
          this.form.promo.success = `Valid promo code for ${res.discountAmount}% discount.`;
        } else {
          this.form.promo.success = null;
          this.form.promo.error = "Invalid Promo Code";
        }
      }
    },
    async onSave() {
      // if (!this.defaultPayment?.id) {
      //   if (!this.form.name.value) {
      //     this.form.name.error = "Please fill the name field";
      //     return false;
      //   }
      //   if (!this.form.number.value) {
      //     this.form.number.error = "Please fill the card number";
      //     return false;
      //   }
      //   if (!this.form.expirationDate.value) {
      //     this.form.expirationDate.error = "Please fill the expiration date";
      //     return false;
      //   }
      //   if (!this.form.cvc.value) {
      //     this.form.cvc.error = "Please fill cvv";
      //     return false;
      //   }
      //   if (!this.form.zipcode.value) {
      //     this.form.zipcode.error = "Please fill postal code";
      //     return false;
      //   }
      //   const expireMonth = this.form.expirationDate.value?.split("/")[0];
      //   const expireYear = this.form.expirationDate.value?.split("/")[1];
      //   this.isLoading = true;
      //   try {
      //     const params = {
      //       paymentMethod: {
      //         firstName: this.state.currentUser?.firstName,
      //         lastName: this.state.currentUser?.lastName,
      //         verificationCode: this.form.cvc.value,
      //         month: expireMonth,
      //         year: expireYear,
      //         number: this.form.number.value?.replace(/[^A-Z0-9]/gi, ""),
      //         methodType: "credit_card",
      //         type: "payment",
      //         billingZip: this.form.zipcode.value,
      //       },
      //     };

      //     console.log(params, "params");
      //     const user = await this.actions.user.updateUserProfile(params);
      //     console.log(user, "user");
      //     if (user?.id) {
      //       const defaultPayment = this.state.currentUser.paymentMethods?.find(
      //         (p) => p.isValid && p.isDefault
      //       );
      //       const { upgradeSubscription } =
      //         await this.actions.user.upgradeSubscription({
      //           productId: this.product?.id,
      //           userId: this.state.currentUser?.id,
      //           paymentMethodId: defaultPayment?.id,
      //           priceId: this.product?.pricing[0]?.id,
      //           promoCode: this.form.promo.value,
      //         });
      //       console.log(upgradeSubscription, "upgradeSubscription");
      //       // if (upgradeSubscription?.user?.id) {
      //       await this.actions.user.getUserById();
      //       this.actions.alert.showSuccess({
      //         message: "Upgraded plan successfully",
      //       });
      //       // this.actions.order.getOrders({where: {user: {id: this.state.currentUser?.id}}})
      //       this.$router.push({
      //         name: "Order Success",
      //         query: { id: get(upgradeSubscription, "order[0].id") },
      //       });
      //       // }
      //     }
      //   } catch (e) {
      //     console.log(e);
      //   } finally {
      //     this.isLoading = false;
      //   }
      // } else {
      //   try {
      //     this.isLoading = true;
      //     const { upgradeSubscription } =
      //       await this.actions.user.upgradeSubscription({
      //         productId: this.product?.id,
      //         userId: this.state.currentUser?.id,
      //         paymentMethodId: this.defaultPayment?.id,
      //         priceId: this.product?.pricing[0]?.id,
      //       });
      //     console.log(upgradeSubscription, "upgradeSubscription");
      //     // if (upgradeSubscription?.user?.id) {
      //     await this.actions.user.getUserById();
      //     this.actions.alert.showSuccess({
      //       message: "Upgraded plan successfully",
      //     });
      //     // this.actions.order.getOrders({where: {user: {id: this.state.currentUser?.id}}})
      //     this.$router.push({
      //       name: "Order Success",
      //       query: { id: get(upgradeSubscription, "order[0].id") },
      //     });
      //     // }
      //   } catch (e) {
      //     console.log(e);
      //   } finally {
      //     this.isLoading = false;
      //   }
      // }

      this.isLoading = true;
      //   await this.onSave(this.formData);
      if (this.discount === 100) {
        const { upgradeSubscription } = await this.actions.user.upgradeSubscription({
          productId: this.product?.id,
          userId: this.state.currentUser?.id,
          paymentMethodId: "PREPAID",
          priceId: this.product?.pricing[0]?.id,
          promoCode: this.form.promo.value,
          siteId: Settings.siteId,
        });

        console.log(upgradeSubscription, "upgradeSubscription 3");

        // if (upgradeSubscription?.user?.id) {
        await this.actions.user.getUserById();
        this.actions.alert.showSuccess({
          message: "Upgraded plan successfully",
        });
        this.$router.push("/dashboard");
        this.isLoading = false;
      } else {
        const { error: submitError } = await this.elements.submit();
        if (submitError) {
          console.log(submitError);
          this.isLoading = false;
          return false;
        }
        const { error } = await this.stripe.confirmSetup({
          elements: this.elements,
          clientSecret: this.clientSecret,
          confirmParams: {
            return_url: "https://example.com/order/123/complete",
          },
          redirect: "if_required",
        });
        console.log(error, "error");
        if (error?.message) {
          this.actions.alert.showError({ message: error?.message });
          this.isLoading = false;
        } else {
          const { getStripePaymentMethods } = await this.actions.user.getStripePaymentMethods({
            customerId: this.customerId,
            type: "card",
          });
          console.log(getStripePaymentMethods);
          const payment = getStripePaymentMethods?.data?.[0];
          if (payment?.id) {
            const user = await this.actions.user.updateUserProfile({
              userId: this.state.currentUser?.id,
              paymentMethod: {
                firstName: this.state.currentUser.firstName,
                lastName: this.state.currentUser.lastName,
                month: ("0" + payment?.card?.exp_month?.toString()).slice(-2),
                year: payment?.card?.exp_year?.toString(),
                paymentMethodId: payment?.id,
                type: "payment",
                methodType: "credit_card",
                brand: payment?.card?.brand,
                billingZip: payment?.billing_details?.address?.postal_code,
                last4: payment?.card?.last4,
              },
            });

            if (user) {
              this.actions.alert.showSuccess({
                message: "Credit card added successfully.",
                title: "Payment Method",
              });
              const defaultPayment = this.state.currentUser.paymentMethods?.find((p) => p.isValid && p.isDefault);

              const { upgradeSubscription } = await this.actions.user.upgradeSubscription({
                productId: this.product?.id,
                userId: this.state.currentUser?.id,
                paymentMethodId: defaultPayment?.id,
                priceId: this.product?.pricing[0]?.id,
                promoCode: this.form.promo.value,
              });

              console.log(upgradeSubscription, "upgradeSubscription 4");

              await this.actions.user.getUserById();

              this.actions.alert.showSuccess({
                message: "Upgraded plan successfully",
              });

              this.$router.push("/dashboard");
            } else {
              this.actions.alert.showError({
                message: "Failed to add credit card",
                title: "Payment Method",
              });
              this.isLoading = false;
            }
            this.$emit("onClose");
          }
        }
        this.isLoading = false;
      }
    },
    async getPaymentInfo() {
      try {
        const { stripePaymentSheet } = await this.actions.user.stripePaymentSheet({
          userId: this.state.currentUser?.id,
          type: "setup",
          paymentTypes: ["cards"],
        });
        this.customerId = stripePaymentSheet?.customer;
        this.clientSecret = stripePaymentSheet?.setupIntent;
        this.stripe = Stripe(Settings.stripeKey);
        console.log(this.stripe, "stripe");
        const options = {
          mode: "setup",
          currency: "usd",
          // Fully customizable with appearance API.
          appearance: {
            /*...*/
          },
        };
        this.elements = this.stripe.elements(options);
        const paymentElement = this.elements.create("payment");
        paymentElement.mount("#payment-element");
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    await this.getPaymentInfo();
    const products = await this.actions.product.getProducts({
      where: { name: "Individual" },
      getValues: true,
    });
    this.product = products[0];
    console.log(this.product, "product");
  },
};
</script>

<template>
  <fieldset class="space-y-5">
    <div
      class="relative flex items-start"
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="flex h-5 items-center">
        <input
          :id="`item-${index}`"
          aria-describedby="comments-description"
          name="comments"
          type="checkbox"
          v-model="selectedItems"
          :value="item"
          class="
            h-4
            w-4
            rounded
            border-gray-300
            text-red-600
            focus:ring-red-500
          "
          @focus="$emit('onFocus')"
        />
      </div>
      <div class="ml-3 text-sm">
        <Text
          :for="`item-${index}`"
          size="sm"
          weight="medium"
          color="gray-700"
          :content="item"
        />
      </div>
    </div>
  </fieldset>
</template>

<script>
/**
- Checkbox List with text
- options to  title,description
**/
import Text from "../../../atoms/Text/Text.vue";
export default {
  components: {
    Text,
  },
  props: {
    /**
    list of items
    */
    items: {
      type: Array,
      default: () => [],
    },
    /**
    state of checkbox.
    */
    selected: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedItems: {
      get() {
        return this.selected;
      },
      set(newValue) {
        this.$emit("update:selected", newValue);
      },
    },
  },
};
</script>
